<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="cards.cardsData">
      <CardsTable
        v-on:setCardData="cards.card.fillData($event)"
        :cardsData="cards.cardsData"
      />

      <ActionsData :actionsData="cards.card" />
      <CardInfo :card="cards.card" />
      <CardEdit :card="cards.card" v-on:refresh="getAllCards()" />

      <Pagination
        v-if="!isLoading"
        :paginationData="cards.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <CardFloatBtns
      :theFilterData="cards.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../utils/constants";
import ActionsData from "./../../components/general/ActionsData.vue";
import CardsTable from "./../../components/cards/CardsTable.vue";
import CardInfo from "./../../components/cards/CardInfo.vue";
import CardEdit from "./../../components/cards/CardEdit.vue";
import CardFloatBtns from "./../../components/cards/CardFloatBtns.vue";
import Pagination from "./../../components/general/Pagination.vue";
import Cards from "./../../models/cards/Cards";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CardsTable,
    CardInfo,
    CardEdit,
    CardFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      cards: new Cards(),
    };
  },
  methods: {
    changePage(page) {
      this.cards.pagination.selfPage = page;
      this.getAllCards();
    },
    search(data) {
      this.cards.filterData.fillData(data);
      this.cards.cardsData = [];
      this.getAllCards();
    },
    async getAllCards() {
      this.isLoading = true;
      try {
        const response = await this.cards.card.getAllCards(
          this.language,
          this.userAuthorizeToken,
          this.cards.pagination,
          this.cards.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.cards.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.cards.cardsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.cards.cardsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.cards.cardsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.cards.cardsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllCards();
  },
};
</script>
