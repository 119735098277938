<template>
  <div v-if="cardsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Cards.serialNumber") }}</th>
            <th>{{ $t("Cards.saleStatus") }}</th>
            <th>{{ $t("Cards.salePrice") }}</th>
            <th>{{ $t("Cards.suspendStatus") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("actionsData.modelName") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(card, index) in cardsData" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(card.cardSerialNumber) }}</td>
            <td>{{ isDataExist(card.saleTypeNameCurrent) }}</td>
            <td>{{ isDataExist(card.cardSalePriceView) }}</td>
            <td>{{ isDataExist(card.suspendTypeNameCurrent) }}</td>
            <td>
              <button
                v-b-modal.CardInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setCardData(card)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-if="
                  checkPrivilege(usersPrivilegeData.usersPrivilegeCards.update)
                "
                v-b-modal.CardEdit
                class="btn p-0"
                :title="$t('edit')"
                @click="setCardData(card)"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-if="
                  checkPrivilege(usersPrivilegeData.usersPrivilegeCards.update)
                "
                v-b-modal.ActionsData
                class="btn p-0"
                :title="$t('actionsData.modelName')"
                @click="setCardData(card)"
              >
                <img src="@/assets/images/actions-data.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { isDataExist } from "./../../utils/functions";

export default {
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["cardsData"],
  methods: {
    setCardData(card) {
      this.$emit("setCardData", card);
    },
    isDataExist,
    checkPrivilege,
  },
};
</script>
