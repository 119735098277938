<template>
  <b-modal id="CardInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/cards.svg" class="icon-lg" />
        {{ $t("Cards.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.cardSerialNumber"
            :title="$t('Cards.serialNumber')"
            :imgName="'serialNumber.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.saleTypeNameCurrent"
            :title="$t('Cards.saleStatus')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.cardSalePriceView"
            :title="$t('Cards.salePrice')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="formateDateTimeLang(card.saleDate, card.saleDate)"
            :title="$t('Cards.saleDateTime')"
            :imgName="'dateAndTime.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.suspendTypeNameCurrent"
            :title="$t('Cards.suspendStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.suspendReasonCurrent"
            :title="$t('Cards.suspendReason')"
            :imgName="'reason.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                card.startActivationDate,
                card.startActivationTime
              )
            "
            :title="$t('Cards.startActivationDateTime')"
            :imgName="'dateAndTime.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              formateDateTimeLang(
                card.endActivationDate,
                card.endActivationTime
              )
            "
            :title="$t('Cards.endActivationDateTime')"
            :imgName="'dateAndTime.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.durationActivation"
            :title="$t('Cards.durationActivation')"
            :imgName="'dateAndTime.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.userOwnerData.userNameCurrent"
            :title="$t('Cards.userOwnerName')"
            :imgName="'users.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.countEnquiryQrCodeVCD"
            :title="$t('Cards.countEnquiryQrCodeVCD')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.countQrCodeSessionsPurchasestotal"
            :title="$t('Cards.countQrCodeSessionsPurchasestotal')"
            :imgName="'number.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.countQrCodeSessionsPurchasesInArchive"
            :title="$t('Cards.countQrCodeSessionsPurchasesInArchive')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="card.countQrCodeSessionsPurchasesNotArchive"
            :title="$t('Cards.countQrCodeSessionsPurchasesNotArchive')"
            :imgName="'number.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "./../../components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "./../../utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["card"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
