<template>
  <b-modal id="CardEdit" scrollable centered hide-footer>
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/pencil.svg" class="icon-lg" />
        {{ $t("Cards.edit") }}
      </h3>
    </template>

    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="'suspendTypeToken'"
        :value="card.suspendTypeToken"
        :options="suspendTypeTokenOptions"
        v-on:changeValue="card.suspendTypeToken = $event"
        :title="$t('Cards.suspendStatus')"
        :imgName="'type.svg'"
      />

      <TextArea
        :className="'col-md-12'"
        :id="'suspendReasonAr'"
        :value="card.suspendReasonAr"
        v-on:changeValue="card.suspendReasonAr = $event"
        :title="$t('Cards.suspendReasonAr')"
        :imgName="'reason.svg'"
      />
      <TextArea
        :className="'col-md-12'"
        :id="'suspendReasonEn'"
        :value="card.suspendReasonEn"
        v-on:changeValue="card.suspendReasonEn = $event"
        :title="$t('Cards.suspendReasonEn')"
        :imgName="'reason.svg'"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="updateCard"
      >
        {{ $t("yes") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('CardEdit')"
      >
        {{ $t("no") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import { STATUS } from "./../../utils/constants";
import { setDataMultiLang } from "./../../utils/functions";
import CustomSelectBox from "./../../components/general/CustomSelectBox.vue";
import TextArea from "./../../components/general/TextArea.vue";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      suspendTypeTokenOptions: [],
    };
  },
  props: ["card"],
  methods: {
    async updateCard() {
      this.isLoading = true;
      try {
        let response = await this.card.updateCard(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("CardEdit");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfSuspendTypes() {
      this.isLoading = true;
      this.suspendTypeTokenOptions = [];
      this.suspendTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listSuspendType;
      for (let item in genders) {
        this.suspendTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfSuspendTypes();
  },
};
</script>
